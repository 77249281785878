import styled from 'styled-components';

export const Banner = styled.div`
  position: relative;
  background-color: black;
  height: 63vh;
  color: white;
  text-align: center;
  margin-bottom: 280px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    height: 40vh;
    margin-bottom: 200px;
  }
`;

export const BannerTextWrap = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
  padding-top: 130px;

  h1 {
    margin-bottom: 10px;
  }
  p {
    font-family: 'Elevon';
    letter-spacing: 2.2px;
    font-size: 14px;
    text-transform: uppercase;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    padding-top: 20px;
  }
`;

export const BannerImg = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(200px);
`;

export const BannerImgWrap = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 270px;
    color: transparent;
    -webkit-text-stroke: 1px rgba(211, 211, 211, 1);
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    z-index: 100;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    img {
      height: 600px;
      width: 600px;
    }
    h2 {
      display: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    img {
      height: 300px;
      width: 300px;
    }
  }
`;
