import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'layouts/MainLayout';
import { Latest, SEO, TextBody } from 'components';
import { InnerWrapCenter } from 'components/common/Containers/styled';
import { DarkScheme } from 'helpers/navColors.js';

import {
  Container,
  Banner,
  BannerTextWrap,
  BannerImg,
  BannerImgWrap,
} from './styled';

const SelectorTemplate = ({ data }) => {
  const { selector, latestSelectors } = data;
  const {
    body,
    subtitle,
    metaDescription,
    coverImage,
    publishedDate,
    artists,
    authors,
  } = selector;

  return (
    <MainLayout navColor={DarkScheme} headerColor='white'>
      <SEO
        title={`${artists[0].englishName} | Selector`}
        description={metaDescription}
        image={coverImage.url}
        imageShare={coverImage.url}
        isBlogPost
      />

      <Banner>
        <BannerTextWrap>
          <h1>{artists[0].englishName}</h1>
          <p>{publishedDate}</p>
        </BannerTextWrap>
        <BannerImg>
          <BannerImgWrap>
            <img src={coverImage.url} alt='' />
            <h2>Selector</h2>
          </BannerImgWrap>
        </BannerImg>
      </Banner>

      <TextBody
        body={body}
        subtitle={subtitle}
        artists={artists}
        authors={authors}
      />

      <InnerWrapCenter>
        <Latest
          data={latestSelectors}
          sectionTitle='selectors'
          link='/selector'
        />
      </InnerWrapCenter>
    </MainLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    selector: strapiFeatures(slug: { eq: $slug }) {
      title
      body
      subtitle
      metaDescription
      publishedDate(formatString: "MMMM D YYYY")
      authors {
        englishName
        slug
      }
      artists {
        englishName
        slug
      }
      coverImage {
        url
      }
    }
    latestSelectors: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { eq: "Selector" } }, slug: { ne: $slug } }
      limit: 4
    ) {
      edges {
        node {
          artists {
            englishName
          }
          coverImage {
            url
          }
          slug
          selectorID
        }
      }
    }
  }
`;

export default SelectorTemplate;
